@import '../../classes.module';
.redeemHeader {
  border-bottom: 4px solid $primary-color;
  text-align: center;
  max-width: 88%;
  width: 100%;
  padding: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  font-weight: 600;
  font-size: 2em;
  color: $text-color;
  @media screen and (max-width: 1024px) {
    margin-bottom: 40px;
  }
}
.redeemSection{
  line-height: 1.5;
  @media screen and (max-width: 1680px) {
      font-size: 11px;
  }
  @media screen and (max-width: 1240px) {
    font-size: 10px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
}

.redeemRow{
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 40px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 40px;
  }
}
.reverseRow{
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}

.redeemTextContainer {
  width: 49%;
  border-radius: 24px;
  border-color: $primary-color;
  border-width: 2px;
  border-style: solid;
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-top: 16px;
  }
}
.mainTitle {
  font-weight: 700;
  font-size: 3.5em;
  text-align: center;
  color: $primary-color;

  @media screen and (max-width: 1024px) {
    font-size: 2em;
  }
}
.redeemSubTitle {
  font-weight: 500;
  font-size: 2.5em;
  margin-top: 48px;
  @media screen and (max-width: 1024px) {
    font-size: 1.5em;
    margin-top: 1.5rem;
  }
}
.redeemEarnTitle {
  margin-top: 6rem;
  @media screen and (max-width: 1024px) {
    margin-top: 1.5rem;
  }
}
.howItWorksList {
  list-style: none;
  margin: 56px 0 0;
  padding: 0;
}

.howItWorksList li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 1.5em;
  @media screen and (max-width: 1024px) {
    font-size: 1em;
  }
}

.howItWorksList li span {
  background: #e5d7cc;
  color: white;
  font-weight: bold;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
  @media screen and (max-width: 1024px) {
    width: 40px;
    height: 40px;

  }
}

.howItWorksList a {
  color: $primary-color;
  text-decoration: underline;
}

.defaultText{
  font-weight: 400;
  font-size: 1.5em;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 1em;

  }
}
.earnList{
  padding: 0;
  margin: 24px 0 40px;

}
.earnList li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 1.5em;
  color: $brawn-color;
  @media screen and (max-width: 1024px) {
    font-size: 1em;
  }
}

.earnList li .point {
  background: $brawn-color;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
}
.tag{
  font-weight: 500;
  font-size: 1.125em;
  text-align: center;
  span{
    color: $primary-color;
  }
  @media screen and (max-width: 1024px) {
    font-size: 1em;
  }
}
.additional{
  font-weight: 500;
  font-size: 0.875em;
  text-align: center;

}

.smallTitle{
  font-weight: 700;
  font-size: 2em;
  text-align: center;
  color: $primary-color;
  @media screen and (max-width: 1024px) {
    font-size: 1.5em;
  }
}
.star{
  width: 32px;
  height: 32px;
  color: $brawn-color;
}
.logo{
  max-width: 204px;
  margin-top: 24px;
  max-height: 64px;
}
